import storeManager from '../index';
import { CommonTypes, AccountTypes } from '../types';
import { getAPIErrorMessage } from './../../constants/errors';
import { AccountAction } from '../../store/actions';


export const postAsync = async ({
  url,
  param,
  afterCallback,
  errorCallback,
  engageCompanyId = true,
  bodySetAsQuery = true,
  showLoading = true,
}) => {
  return asyncRequest({
    url: url,
    method: 'POST',
    param: param,
    afterCallback: afterCallback,
    errorCallback: errorCallback,
    engageCompanyId: engageCompanyId,
    bodySetAsQuery: bodySetAsQuery,
    showLoading: showLoading,
  });
};

export const getAsync = async ({
  url,
  param,
  afterCallback,
  errorCallback,
  engageCompanyId = false,
  showLoading = true,
}) => {
  return await asyncRequest({
    url: url,
    method: 'GET',
    param: param,
    afterCallback: afterCallback,
    errorCallback: errorCallback,
    engageCompanyId: engageCompanyId,
    bodySetAsQuery: false,
    showLoading: showLoading,
  });
};

const getHeader = ({
  url,
  method,
  param,
  engageCompanyId = false,
  bodySetAsQuery = true,
}) => {
  const companyID = storeManager.store.getState().common?.Company?.ID;
  if (engageCompanyId) {
    if (param) param.companyID = companyID;
    else param = { ...param, companyID };
  }

  var parameters = {
    headers: { 'Content-Type': 'application/json' },
    method: method,
  };

  if (storeManager.store.getState().account.CurrentUserSession != null) {
    parameters.headers = {
      ...parameters.headers,
      Authorization: `Bearer ${storeManager.store.getState().account.CurrentUserSession.SessionToken
        }`,
    };
    param.SessionToken =
      storeManager.store.getState().account.CurrentUserSession.SessionToken;
  }

  if (method === 'POST' && !!param && !bodySetAsQuery) {
    var data = new URLSearchParams();
    for (var key in param) {
      data.append(key, param[key] == null || param[key] == undefined ? '' : param[key]);
    }
    parameters.body = data;
  } else {
    for (var key in param) {
      if (param[key] == null || param[key] == undefined) param[key] = '';
    }
    url = url + '?' + new URLSearchParams(param);
  }
  var baseURL = "";
  var domain = window.location.hostname;
  switch (domain) {
    case "alert.restajet.com":
      baseURL = "https://api.restajet.com/admin_v1/";
      break;
    case "alert-test-nl.restajet.com":
      baseURL = "https://api-test-nl.restajet.com/admin_v1/";
      break;
    case "alert-stage-us.restajet.com":
      baseURL = "https://api-stage-us.restajet.com/admin_v1/";
      break;
    case "alert-prod-nl.restajet.com":
      baseURL = "https://api-prod-nl.restajet.com/admin_v1/";
      break;
      case "alert-us.restajet.com":
        baseURL = "https://api-us.restajet.com/admin_v1/";
        break;
    case "localhost":
      baseURL = "https://api-test-nl.restajet.com/admin_v1/";
      break;
    default:
      baseURL = "https://api-us.restajet.com/admin_v1/";
      break;
  }

  url = baseURL + url.replace('%25', '%');
  return { url, parameters };
};

const asyncRequest = async ({
  url,
  method,
  param,
  afterCallback,
  errorCallback,
  engageCompanyId = false,
  bodySetAsQuery = false,
  showLoading = true,
}) => {
  storeManager.store.dispatch({
    type: CommonTypes.AsyncStarted,
    payload: showLoading,
  });

  try {
    var header = getHeader({
      url: url,
      method: method,
      param: param,
      engageCompanyId: engageCompanyId,
      bodySetAsQuery: bodySetAsQuery,
    });

    const response = await fetch(header.url, header.parameters);

    if (!response.ok) {
      if (response.status === 400) {
        //Todo access denied;
      }
      storeManager.store.dispatch({
        type: CommonTypes.AsyncError,
        payload: { Error: { message: 'Error' } },
      });
      return;
    }

    const result = await response.json();

    storeManager.store.dispatch({
      type: CommonTypes.SetOfflineMode,
      payload: false,
    });

    if (result.ErrorCode === 0) {
      storeManager.store.dispatch({ type: CommonTypes.AsyncEnd });
      if (afterCallback) afterCallback(result);
    }
    else if (result.ErrorCode === 21) {
      storeManager.store.dispatch({ type: AccountTypes.Logout });
      //storeManager.store.dispatch(push("/Login"));
    }
    else {
      const errMsg = getAPIErrorMessage(result.ErrorCode);
      if (errorCallback) {
        errorCallback(result);
        storeManager.store.dispatch({ type: CommonTypes.AsyncEnd });
      } else
        storeManager.store.dispatch({
          type: CommonTypes.AsyncError,
          payload: {
            Error: {
              title: result.Title,
              message: result.ResultMessage || errMsg,
            },
          },
        });
    }
  } catch (e) {
    // console.log('response description', e.description);
    // console.log('response TypeError is', e instanceof TypeError);
    // console.log('response name', e.name);
    // console.log('response title', e.title);
    // console.log('response message', e.message);
    if (e.message === 'Failed to fetch') {
      storeManager.store.dispatch({
        type: CommonTypes.SetOfflineMode,
        payload: true,
      });
    } else {
      storeManager.store.dispatch({
        type: CommonTypes.AsyncError,
        payload: { Error: e },
      });
    }
  }
};

export const showErrorMessage = (message, title) => {
  if (!title) title = 'Error';
  storeManager.store.dispatch({
    type: CommonTypes.GlobalError,
    payload: { Error: { message, title } },
  });
};

const ApiHelper = {
  postAsync,
  getAsync,
  showErrorMessage,
};

export default ApiHelper;
